import { AuthService } from 'elbuild-ui-lib-core';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MetaService } from './core/services/meta.service';
import { combineLatest, filter, map, mergeMap } from 'rxjs';
import { PageType } from 'src/conf/constant';
import { StorefrontService } from './core/services/storefront.service';
import { VersionCheckService } from './core/services/version-check.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {


  constructor(private translate: TranslateService,
    @Inject(PLATFORM_ID) public platformId: Object,
    private authservice: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private tagService: MetaService,
    private storefrontservice: StorefrontService,
    private versionCheckService: VersionCheckService
  ) {

    if (true || (isPlatformBrowser(this.platformId) && environment.production)) {

      router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe(event => {
          this.versionCheckService.checkVersion('/assets/version.json', (event as NavigationStart).url)
        });

    }

    this.translate.use(environment.lang);
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.add(environment.lang);
    }


    this.authservice.getLoggedUser().subscribe({
      next: () => { },
      error: (error: any) => {
        console.error('error', error);
      }
    })

  }

  ngOnInit(): void {
    this.tagService.init();

    this.storefrontservice.getStorefront();

  }

  ngAfterViewInit(): void {

    this.router.events.pipe(
      filter(event => {
        if (event instanceof NavigationEnd) {
          const routerUrl = event['urlAfterRedirects'];
          if (routerUrl && typeof routerUrl === 'string') {
            if (isPlatformBrowser(this.platformId)) {
              //this.gaService.pageView(event['urlAfterRedirects']); non lo tracciamo più da codice ma lo lascio se si volesse rimettere
            }
          }
          return true;
        }
        return false;
      }))
      .pipe(map(() => {
        return this.route;
      })).pipe(
        map(route => {
          while (route['firstChild']) {
            route = route['firstChild'];
          }
          return route;
        })).pipe(
          filter(route => route['outlet'] === 'primary')).subscribe(route => {
            combineLatest([route['data'], route['url']]).subscribe(data => {

              if (environment.inactivepages && environment.inactivepages.includes(this.router.url)) {
                this.router.navigate(['/404'], { skipLocationChange: true });
              } else
                if (data[0] && data[1]) {
                  this.tagService.setPageTags(data[0], this.router.url);

                }
            });

          });


  }

}
