import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './layout/base/base.component';
import { AuthenticationGuardService, Login } from 'elbuild-ui-lib-core';
import { environment } from 'src/environments/environment';
import { pathinlangs } from 'src/conf/pages';

const routesothers: Routes = [

  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: pathinlangs.toolbox[environment.lang],
        loadChildren: () => import('./pages/toolbox/toolbox.module').then(module => module.ToolboxModule),
        canActivate: [AuthenticationGuardService],
        canActivateChild: [AuthenticationGuardService]
      },
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
      },
      {
        path: 't',
        loadChildren: () => import('./pages/taxonomy/taxonomy.module').then(module => module.TaxonomyModule),
      },
      {
        path: pathinlangs.catalog[environment.lang],
        loadChildren: () => import('./pages/catalog/catalog.module').then(module => module.CatalogModule),
      },
      {
        path: 'p',
        loadChildren: () => import('./pages/product/product.module').then(module => module.ProductModule),
      },
      {
        path: pathinlangs.contacts[environment.lang],
        loadChildren: () => import('./pages/contacts/contacts.module').then(module => module.ContactsModule),
      },
      {
        path: pathinlangs.login[environment.lang],
        loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule),
      },

      {
        path: pathinlangs.network[environment.lang],
        loadChildren: () => import('./pages/network/network.module').then(module => module.NetworkModule),
      },

      {
        path: pathinlangs.webinar[environment.lang],
        loadChildren: () => import('./pages/webinar/webinar.module').then(module => module.WebinarModule),
      },
      {
        path: pathinlangs.students[environment.lang],
        loadChildren: () => import('./pages/students/students.module').then(module => module.StudentsModule),
      }, {
        path: pathinlangs.teacher[environment.lang],
        loadChildren: () => import('./pages/teacher/teacher.module').then(module => module.TeacherModule),
      }, {
        path: pathinlangs.signup[environment.lang],
        loadChildren: () => import('./pages/signup/signup.module').then(module => module.SignupModule),
      }, {
        path: pathinlangs.about[environment.lang],
        loadChildren: () => import('./pages/about/about.module').then(module => module.AboutModule),
      }, {
        path: pathinlangs.download[environment.lang],
        loadChildren: () => import('./pages/download/download.module').then(module => module.DownloadModule),
      }, {
        path: pathinlangs.reallives[environment.lang],
        loadChildren: () => import('./pages/lives/lives.module').then(module => module.LivesModule),
      }, {
        path: pathinlangs.awards[environment.lang],
        loadChildren: () => import('./pages/awards/awards.module').then(module => module.AwardsModule),
      }, {
        path: pathinlangs.equilibrium[environment.lang],
        loadChildren: () => import('./pages/equilibrium/equilibrium.module').then(module => module.EquilibriumModule),
      },
      {
        path: '404',
        loadChildren: () => import('./pages/notfound/notfound.module').then(module => module.NotFoundModule),
      },
      {
        path: 'n',
        loadChildren: () => import('./pages/article/article.module').then(module => module.ArticleModule),
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];



const routesen: Routes = [
  {
    path: 'login-digitalhub',
    loadChildren: () => import('./pages/login-digitalhub/login-digitalhub.module').then(module => module.LoginDigitalhubModule),
  },
  ...routesothers
];

const routesit: Routes = [
  {
    path: pathinlangs.request[environment.lang],
    loadChildren: () => import('./pages/request/request.module').then(module => module.RequestModule),
  },
  ...routesothers
]

const routeschrula: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        redirectTo: 'catalog',
        pathMatch: 'full',
      },
      {
        path: 'catalog',
        loadChildren: () => import('./pages/catalog/catalog.module').then(module => module.CatalogModule),
      },
      {
        path: 'p',
        loadChildren: () => import('./pages/product/product.module').then(module => module.ProductModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contacts/contacts.module').then(module => module.ContactsModule),
      },
      {
        path: 'download',
        loadChildren: () => import('./pages/download/download.module').then(module => module.DownloadModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule),
      },
      {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then(module => module.SignupModule),
      },
      {
        path: '404',
        loadChildren: () => import('./pages/notfound/notfound.module').then(module => module.NotFoundModule),
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];

const routes: Record<string, Routes> = { 'en': routesen, 'it': routesit, 'de': routesothers, 'fr': routesothers, 'es': routesothers, 'chrula': routeschrula };

@NgModule({
  imports: [RouterModule.forRoot(routes[environment.lang], {
    scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
