import { environment } from 'src/environments/environment';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (isPlatformBrowser(this.platformId)) {

      if (req.url.startsWith('/ws/') && !req.url.startsWith(environment.relativeapiurl)) {
        const u = req.url;
        req = req.clone({ url: req.url.replace('/ws/', environment.relativeapiurl) });

      }
    }

    return next.handle(req);
  }
}
