<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-5 my-md-4 mb-3">
        <img src="/assets/images/logos/{{lang}}.svg"
             class="img-fluid logo">
        <br>
        {{"footer-partof" | translate}}
        <a href="https://www.elionline.com/"
           target="_blank">
          <strong>ELi Publishing Group</strong>
        </a>

      </div>
      <div class="col-lg-3 my-md-4 mb-3">
        <span class="subtitle">{{"website" | translate}}</span>
        <ul>
          <li>
            <a [eliRouterLink]="'/about'">{{"about" | translate}}</a>
          </li>
          <li>
            <a [eliRouterLink]="'/contacts'">{{"contacts" | translate}}</a>
          </li>
          <li>
            <a [eliRouterLink]="'/network'">{{"sales-network" | translate}}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3  my-md-4 mb-3">
        <span class="subtitle">{{"legal-info" | translate}}</span>
        <ul>
          <li>
            <a [href]="environment.cookiepolicy"
               target="_blank">
              {{"cookie-policy" | translate}}
            </a>
          </li>
          <li>
            <a [href]="environment.privacypolicy"
               target="_blank">
              {{"privacy-policy" | translate}}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-1  my-md-4 mb-3">
        <div class="social">
          @if (fburl) {
            <a class="circle"
               [href]="fburl"
               target="_blank">
              <i class="fa fa-facebook"
                 aria-hidden="true"></i>
            </a>}
          <a class="circle"
        [href]="youtubelink"
            target="_blank">
            <i class="fa fa-youtube-play"
               aria-hidden="true"></i>
          </a>

        </div>

      </div>
    </div>
  </div>
  <div class="container-fluid  credits">{{"credits" | translate}}</div>
</footer>
