import { Component, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService, SharedDataService } from 'elbuild-ui-lib-core';
import { Teacher } from 'eli-ui-lib';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.' + environment.template + '.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  lang = environment.lang
  loggeduser: Teacher;
  isSticky: boolean;


  isMenuCollapsed: boolean = true;

  constructor(private sharedDataService: SharedDataService, private authService: AuthService, private router: Router, route: ActivatedRoute) {

    this.sharedDataService.currentLoggedUser.subscribe((data: any) => {
      this.loggeduser = data as Teacher;
    })

    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => route))
      .pipe(map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.isMenuCollapsed = true;
      });
  }


  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const height = 300;
    const offset = window.pageYOffset || document.documentElement.scrollTop;
    this.isSticky = offset > height;
  }
}
