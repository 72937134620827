<section class="header"
         [ngClass]="{'sticky': isSticky }">
  <div class="container">

    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand"
           href="#">
          <img class="img-fluid logo"
               src="/assets/images/logos/{{lang}}.svg">
        </a>

        <button class="navbar-toggler"
                type="button"
                (click)="isMenuCollapsed = !isMenuCollapsed">
          @if(isMenuCollapsed){
            &#9776;
          } @else{
                        <i class="fa fa-times" aria-hidden="true"></i>

          }
        </button>

        <div [ngbCollapse]="isMenuCollapsed"
             class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link"
                 eliRouterLink="/catalog">
                {{"catalogue" | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 eliRouterLink="/teacher">
                {{"teachers" | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 eliRouterLink="/students">
                {{"students" | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 eliRouterLink="/network">
                {{"sales-network" | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 eliRouterLink="/contacts">
                {{"contacts" | translate}}
              </a>
            </li>
          </ul>
          @if (!loggeduser)   {
            <form class="form-inline my-2 my-lg-0">
              <a class="btn btn-outline-secondary"
                 eliRouterLink="/login">
                {{"login" | translate}}
                <i class=" ms-2 fa icon icon-login"
                   aria-hidden="true"></i>

              </a>
              <a class="btn btn-primary"
                 eliRouterLink="/signup">
                {{"signup" | translate}}
                <i class=" ms-2 fa icon icon-signin"
                   aria-hidden="true"></i>

              </a>

            </form>
          }@else {
            <div class="initials">
              {{loggeduser.initials}}
            </div>
            <a (click)="logout()"
               class="btn btn-primary">
              {{"logout" | translate}}
              <i class=" ms-2 icon icon-signin"
                 aria-hidden="true"></i>

            </a>
          }
        </div>
      </div>
    </nav>
  </div>
</section>
