import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class StorefrontHttpInterceptor implements HttpInterceptor {

  storefront: string = environment.storefront;

  constructor() {


  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.storefront) {
      request = request.clone({
        setHeaders: {
          'x-yf-domain': this.storefront
        }
      });
    }
    return next.handle(request);
  }
}

