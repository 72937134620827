// shared/loaders/translate-browser.loader.ts
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }

  public getTranslation(lang: string): Observable<any> {

    return new TranslateHttpLoader(this.http, '/ws/i18n/file/', '').getTranslation(lang);

  }
}

export function translateBrowserLoaderFactory(
  httpClient: HttpClient
) {
  return new TranslateBrowserLoader(httpClient);
}
