import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { VERSION } from 'src/environments/version';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.' + environment.template + '.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  lang = environment.lang;
  environment = environment;
  version = VERSION;
  fburl = environment.facebook;
  youtubelink = environment.youtubeplaylist;


  constructor() {
    console.log(`UI v. ${this.version.version} (commit ${this.version.hash} date ${this.version.date})`);
  }
}
