import 'reflect-metadata';

import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfig, ElbuildUiLibModule } from 'elbuild-ui-lib-core';
import { ElbuildUiComponentslibModule } from 'elbuild-ui-componentslib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from './layout/layout.module';
import { environment } from '../environments/environment';
import { StorefrontHttpInterceptor } from './core/services/storefront.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { Teacher } from 'eli-ui-lib';
import { BrowserStateInterceptor } from './core/ssr/browserstate.interceptor';
import { TranslateBrowserLoader, translateBrowserLoaderFactory } from './core/ssr/translate-browser.loader';
import { pathinlangs } from 'src/conf/pages';

const authConfig: AuthConfig = {
  cookieprefix: 'elicustomer' + 'it',
  usertable: 'access/teacher',
  enablefeatures: false,
  anonwhenexpired: true,
  disableredirectunauthorized: true,
  meendpoint: 'access/teacher/me',
  loginpath: 'login',
  loginpage: '/' + pathinlangs.login[environment.lang],
  i18napi: false,
  userclass: Teacher,

};



@NgModule({
  declarations: [
    AppComponent
  ],

  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    ElbuildUiLibModule.forRoot(authConfig),
    ElbuildUiComponentslibModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: environment.lang
    }),
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    LayoutModule],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StorefrontHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    }

  ]
})
export class AppModule { }
