import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'elbuild-ui-lib-core';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class VersionCheckService {
  private currentVersion = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  constructor(private http: HttpClient,
    private messageService: MessageService) { }

  public scheduleVersionCheck(url: string, frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }


  public checkVersion(versionurl: string, destinationurl?: string) {
    console.log('checkversion');
    // timestamp these requests to invalidate caches
    this.http.get(versionurl + '?t=' + new Date().getTime())
      .pipe(first())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          let hashChanged = this.hasHashChanged(this.currentVersion, hash);

          console.log('version has changed?', hash, hashChanged);
          // If new version, do something
          if (hashChanged) {

            if (destinationurl) {
              location.href = destinationurl;
            } else {
              location.reload();
            }

            console.log('E\' disponibile un nuovo aggiornamento dell\'applicazione, ricarico');

          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentVersion = hash;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  private hasHashChanged(currentHash: string, newHash: string): boolean {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }
}
